import React, {useEffect, useState} from 'react';
// import Popover from "@material-ui/core/Popover";
import { IconButtonV2,Icon, TreeView, TreeItem, ButtonV2,Tooltip, Table,
  TableCell,
  TableRow,
  TableBody,
  Card,
  CardContent,
  CardActions,
  IconButton,
  CardHeader,
  Button ,
  FontIcon 
 } from '@verint/shared-react-components';
import "./currentRequest.css";

function GetServerStatus(props) {   
  const serverName = props.serverName;
  const guacamoleURL = props.guacamoleURL;
  const [labStatus, setLabStatus] = useState([]);
  const [labStatusServer, setLabStatusServer] = useState("");
  const [activeServerAll,setActiveServerAll] = useState();
  const [blurServerNames, setblurServerNames] = useState(false);
  const [loadingServers, setLoadingServers] = useState([]);

  // serverStatus function which get the current status of the server labs.
  const serverStatus = async (command,serverName) => {
    const options = {
      method:"Post",
      headers:{"Content-Type": "application/json"},
      body: `{"token" : "${props.Token}","command":"${command}","serverName":"${serverName}"}`
    }
    const request = new Request('/serverStatus',options);
    try{
      const response = await fetch(request);
      if(response.status == 200){
        const result = await response.json();
        console.log("INFO | Server Status result inside function",result);
        setLabStatus(result);
        setLoadingServers(prevLoadingServers => prevLoadingServers.filter(name => name !== serverName));
      }
      else{
        console.log("INFO | API Status Code",response.statusText);
      }
    }
    catch(error){
      console.error("Error",error);
    }
  }

  useEffect(() => {
    if (serverName) {
      serverStatus("Status", serverName); 
    }
  }, [serverName,labStatusServer]);

  console.log("INFO | labStatus Array ",labStatus);

  // Function to handle Server Power On/Off of the each server lab 
  const handleServerPower = async (command,operation,serverName) => {
    console.log(`Command:  ${command}, operation: ${operation}, serverName: ${serverName}`);
    const options = {
      method:"Post",
      headers:{"Content-Type": "application/json"},
      body: `{"token" : "${props.Token}","command":"${command}","operation":"${operation}","serverName":"${serverName}"}`
    }
    const request = new Request('/manageallserver',options); 
    // const request = new Request('/stopStartServers',options); 
    try{
      const response = await fetch(request);
      if(response.status == 200){
        const result = await response.json();
        console.log("INFO | Power On/Off Status Code",result);
        setLoadingServers(prevLoadingServers => [...prevLoadingServers, serverName]); 
        await serverStatus("Status", serverName); 
        if(result === 0){
          setLabStatusServer(result);
        }
        else if(result === 1){
          setLabStatusServer(result);
        } 
      }
      else{
        console.log("API Status Code",response.statusText);
      }
    }
    catch(error){
      console.error("Error",error);
    }
  }  

  // Function to handle OnClick on the Icon of the each serverName
  const [activeServer, setActiveServer] = useState([]);

  const handleOnClick = async (labStatusList) => {
  const serverName = labStatusList['ServerName'];
  setblurServerNames(true)

  // Update the activeServer state before handling the power action
  setActiveServer(prevActiveServer => {
    const isActive = prevActiveServer.includes(serverName);
   
    if (isActive) {
      return prevActiveServer.filter(name => name !== serverName);
    } else {
      return [...prevActiveServer, serverName];
    }
  });
  // Handle server power based on its current status
  if (labStatusList['Status'] === 'stopped') {
    console.log("PowerOn");
    await handleServerPower('powerOn', '', labStatusList['ServerName']);
    serverStatus("Status", labStatusList['ServerName']);
  } else if (labStatusList['Status'] === 'running') {
    console.log("PowerOff");
    await handleServerPower('powerOff', '', labStatusList['ServerName']);
    serverStatus("Status", labStatusList['ServerName']);
  } else {
    console.log("Loading...");
  }

  // Update the activeServer state again after the power action completes
  setActiveServer(prevActiveServer => {
    const isActive = prevActiveServer.includes(serverName);
    
    if (isActive) {
      return prevActiveServer.filter(name => name !== serverName);
    } else {
      return [...prevActiveServer, serverName];
    }
  });
  serverStatus("Status", serverName);
  setblurServerNames(false)
  };

  // Function to update the 'expandedItems' state with new values for TreeView
  const generateDynamicIndices = () => {
    return Array.isArray(labStatus) ? labStatus.map((_, index) => index.toString()) : [];
  }
  const [expandedItems, setExpandedItems] = useState(['Application Training', 'icon-folder', 'All', ...generateDynamicIndices()]);
  const handleExpandedItemsChange = (newExpandedItems) => {
    setExpandedItems(newExpandedItems);
  };
  if(serverName == null){
    return<>
    </>
  }
  else{
    return<>

      
      <div>
      <TreeView
      // defaultExpandedItems={expandedItems}
      defaultExpanded={expandedItems}
      onExpandedItemsChange={handleExpandedItemsChange}
      selection='single'
      defaultSelectFirstNode={false}
      >
        <TreeItem
          label="Application Training"
          nodeId="Application Training"
          onCheckBoxClick={function noRefCheck(){}}
          // style={{ display: 'flex', alignItems: 'center' }}
        >
          <TreeItem
            label={serverName}
            nodeId="icon-folder"
            // style={{ display: 'flex', alignItems: 'center' }}
          >
            {/* Displaying All Server with respective status icon*/}
            {/* <div className={`serverNames ${blur ? 'blur' : ''}`}> */}
            <div className={`serverNames ${blurServerNames ? 'blurServerNames' : ''}`}>
            <TreeItem        
            // iconType="icon-template"
            label={Array.isArray(labStatus) && labStatus.some(labStatusList => labStatusList['Status'] === "stopped") ? (
              <>
                <div style={{ float: "left" }}>
                <Tooltip
                  arrow
                  classes={{
                    popper: 'storybook-popper-class',
                    tooltip: 'storybook-tooltip-class'
                  }}
                  headerText=""
                  placement="bottom-end"
                  text={activeServerAll ? "Loading" : "Click here to power on all server"} 
                  type="basic"
                >
                  <span>
                  <IconButtonV2
                    className={activeServerAll ? "icon-active" : "icon-play-circle"}
                    fontSize="small"
                    labelClassName=""
                    iconColor={activeServerAll ? "" : "#ff0000"}
                    text=""
                    type=""
                    onClick={ async () => {
                      console.log(serverName);
                      setActiveServerAll(true)
                      setblurServerNames(true)
                      await handleServerPower('powerOn', 'all', serverName);
                      serverStatus("Status", serverName);
                      setActiveServerAll(false)
                      setblurServerNames(false)
                    }}
                  />
                    </span>
                    </Tooltip>
                </div>
                <div style={{ paddingLeft: "25px" }}> Start all servers </div>
              </>
            ) : Array.isArray(labStatus) && labStatus.some(labStatusList => labStatusList['Status'] === "running") ? (
              <>
                <div style={{ float: "left" }}>
                <Tooltip
                  arrow
                  classes={{
                    popper: 'storybook-popper-class',
                    tooltip: 'storybook-tooltip-class'
                  }}
                  headerText=""
                  placement="bottom-end"
                  text={activeServerAll ? "Loading" : "Click here to power off all server"} 
                  type="basic"
                >
                  <span>
                  <IconButtonV2
                    className={activeServerAll ? "icon-active" : "icon-stop-circle"}       
                    fontSize="small"
                    labelClassName=""
                    iconColor={activeServerAll ? "" : "#008000"}
                    text=""
                    type=""
                    onClick={ async () => {
                      console.log("PowerOff");
                      setActiveServerAll(true)
                      setblurServerNames(true)
                      await handleServerPower('powerOff', 'all', serverName);
                      serverStatus("Status", serverName);
                      setActiveServerAll(false) 
                      setblurServerNames(false)                  
                    }}
                  />
                    </span>
                    </Tooltip>                
                </div>
                <div style={{ paddingLeft: "10px" }}> Stop all servers </div>
              </>
            ) : (
              <>
                <div style={{ float: "left" }}>
                <Tooltip
                  arrow
                  classes={{
                    popper: 'storybook-popper-class',
                    tooltip: 'storybook-tooltip-class'
                  }}
                  headerText=""
                  placement="bottom-end"
                  text="Loading"
                  type="basic"
                >
                  <span>
                  <IconButtonV2
                    className="icon-active"
                    fontSize="small"
                    labelClassName=""
                    iconColor=""
                    text=""
                  />
                  </span>
                  </Tooltip>               
                </div>
                <div style={{ paddingLeft: "25px" }}> Loading... </div>
              </>
            )}
            nodeId="All"
          />  

      {/* Displaying each lab name with respective status icon in loop*/}
      {Array.isArray(labStatus) && labStatus.map((labStatusList,index) => (
      <TreeItem
        key={index}
        // iconType="icon-Desktop-circle"
        label = {
          <div  style={{display: "flex", alignItems: "center"}}>
                <div style={{float: "left"}}>                
                <Tooltip
                  arrow
                  classes={{
                    popper: 'storybook-popper-class',
                    tooltip: 'storybook-tooltip-class'
                  }}
                  headerText=""
                  placement="bottom-end"
                  text={
                    activeServer.includes(labStatusList['ServerName']) || loadingServers.includes(labStatusList['ServerName'])
                      ? "Loading"
                      : labStatusList['Status'] === 'running'
                      ? "Click here to power off"
                      : labStatusList['Status'] === 'stopped'
                      ? "Click here to power on"
                      : "Loading"
                  }
                  type="basic"
                >
                  <span>
                    <IconButtonV2 
                  className={
                    activeServer.includes(labStatusList['ServerName']) || loadingServers.includes(labStatusList['ServerName'])
                      ? "icon-active"
                      : labStatusList['Status'] === 'running'
                      ? "icon-stop-circle"
                      : labStatusList['Status'] === 'stopped'
                      ? "icon-play-circle"
                      : "icon-active"
                  }
                  fontSize='small'
                  labelClassName=""
                  iconColor={
                    activeServer.includes(labStatusList['ServerName']) || loadingServers.includes(labStatusList['ServerName'])
                      ? "" 
                      : labStatusList['Status'] === 'running'
                      ? "#008000"
                      : labStatusList['Status'] === 'stopped'
                      ? "#ff0000"
                      : ""
                    }
                    text=""
                    overflowTooltipProps={{
                      placement: 'right',
                      text: 'Click here to power on/off'
                    }}
                    onClick = { () => handleOnClick(labStatusList)}
                  >           
                  </IconButtonV2>
                  </span>
                </Tooltip>               
                </div>
                {/* <div style={{paddingLeft: "10px"}}> <a href="https://connect.dev.verint.live/#/client/NDA5MzAAYwBteXNxbA" target="_blank">{labStatusList['ServerName']}</a></div> */}
                {/* <div style={{paddingLeft: "10px"}}> {labStatusList['ServerName']}</div> */}
                {labStatusList['Status'] === 'running' ? (
                  <div style={{ paddingLeft: "10px" }}>
                    <Tooltip
                  arrow
                  headerText=""
                  placement="bottom-right"
                  text="Click here to connect server"
                  type="basic"
                  >
                  {/* <a href={`https://connect.dev.verint.live/#/client/${(btoa((labStatusList['GuacamoleServerId']))+'AYwBteXNxbA').replace('=','')}`} target="_blank"> */}
                  <a href={`${guacamoleURL}/#/client/${(btoa((labStatusList['GuacamoleServerId']) + ('\x00c\x00mysql')).replace('=', ''))}`} target="_blank">
                  {labStatusList['ServerName']}
                  </a>
                </Tooltip>

                  </div>) : (
                <div style={{ paddingLeft: "10px" }}>
                  {labStatusList['ServerName']}
                </div>)}               
              </div>
        }
        nodeId={index}
        />         
  )) }
          </div>
          </TreeItem>     
        </TreeItem>   
      </TreeView> 
        </div>      
          </>
        }
      }
export default GetServerStatus;
